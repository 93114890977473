<template>
  <div>
    <div class="background" :class="{'background--giving': button,}">
      <div class="container container-l mx-auto">
        <div class="content">
          <div class="desktop-lines">
            <span></span>
          </div>
          <div class="container-s section-main-content">
            <div class="main-content">
              <div class="caption">
                <h1>{{ header }}</h1>

                <h4 v-if="subHeader">{{ subHeader }}</h4>

                <p v-if="text" class="text">{{ text }}</p>
                <router-link v-if="button" :to="{ name: 'FAQs', hash: '#giving' }">
                  <button class="button btn btn-default">
                    {{ cmsStore.components.form_template.fgGivingFAQs }}
                  </button>
                </router-link>
              </div>
            </div>
          </div>

          <div class="form">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>

    <div class="background--mobile">
      <div class="header" :class="[header === 'Become A Friend' ? 'header-join' : '']">
        <div class="main-content--mobile">
          <div class="caption">
            <h1>{{ header }}</h1>

            <h4 v-if="subHeader">{{ subHeader }}</h4>

            <p v-if="text" class="text">{{ text }}</p>

            <router-link v-if="button" :to="{ name: 'FAQs', hash: '#giving' }">
              <button class="button btn btn-default">
                {{ cmsStore.components.form_template.fgGivingFAQs }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useAuthStore} from "@/stores/authStore";
import {useCmsStore} from "@/stores/cmsStore";
import {useDesignStore} from "@/stores/designStore";

export default {
  name: "FormTemplate",
  props: {
    header: {
      type: String,
      required: true,
    },
    subHeader: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    button: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useAuthStore, useCmsStore, useDesignStore),
  },
};
</script>


<style lang="scss" scoped>
svg {
  position: absolute;
  top: 0;
  left: -70px;
  width: 100%;
  height: 100%;

  line {
    stroke: $blue;
    stroke-width: 700px;
  }
}

.background {
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/hero/Become_A_Friend_Mobile.png");
    background-size: cover;
    background-attachment: fixed;
    @media (min-width: $sm) {
      background-image: url("../assets/images/hero/Become_A_Friend.png");
    }
  }

  &--giving {
    &::before {
      background-image: url("../assets/images/hero/Giving_Mobile.jpg");
      @media (min-width: $sm) {
        background-image: url("../assets/images/hero/Giving.png");
      }
    }
  }

  &--mobile {
    position: relative;
    display: none;
    margin-top: 95px;
    overflow: hidden;
    @include MQ(L) {
      margin-top: 0;
    }
    @media screen and (max-width: 1024px) {
      display: block;
      overflow: hidden;
    }

    .header {
      .main-content--mobile {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          // left: 190px;
          left: 0;
          margin-left: 225px;
          top: 50%;
          background-color: $blue2;
          width: 350px;
          height: 2700px;
          transform: rotate(-45deg) translate(235%, -32%);
          animation: fadeLine 1s ease 0s forwards;
        }

        &:after {
          content: "";
          position: absolute;
          left: 430px;
          top: 50%;
          width: 10px;
          background: white;
          transform: translate(-50%, -50%) rotate(-45deg);
          height: 1200px;
          opacity: 0;
          animation: fadeIn 1s ease forwards 2s;
        }
      }
    }
  }
}

.content {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.caption {
  position: relative;
  z-index: 10;
  padding-top: 260px;
  color: #fff;
  @media (max-width: 1024px) {
    width: 350px;
    max-width: 100%;
  }

  h1 {
    margin-bottom: 20px;
  }


  @media screen and (max-width: 1024px) {
    padding: 190px 15px 190px;

    h1 {
      margin-bottom: 12px;
    }

  }
}

.form {
  padding-top: 260px;
  margin-bottom: -20px;
  margin-right: -15px;

  @media screen and (min-width: 1920px) {
    margin-right: -41px;
  }

  @media screen and (max-width: 1024px) {
    padding: 0;
    margin: 0;
  }
}

.line {
  position: absolute;
  width: 445px;
  height: 1799px;
  background-color: #28677d;
  top: 0;
  transform: rotate(-45deg) translate(102%, -19%);
  margin-left: -5px;
  z-index: 0;

  @media screen and (max-width: 1440px) {
    height: 1800px;
    transform: rotate(-45deg) translate(15%, -12%);
    margin-left: -5px;
    z-index: 0;
  }

  @media screen and (max-width: 1200px) {
    height: 1800px;
    transform: rotate(-45deg) translate(5%, -30%);
    margin-left: -5px;
    z-index: 0;
  }
  @media screen and (max-width: $sm) {
    transform: rotate(45deg) translate(5%, -30%);
  }
}

.button {
  color: #313131;
  background: transparent;
}

.text {
  max-width: 640px;
  // font-size: 17px;
}

.header {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/hero/Giving_Mobile.jpg");
    background-size: cover;
    @media screen and (min-width: $sm) {
      background-image: url("../assets/images/hero/Giving.png");
    }
  }
}

.header-join {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/hero/Become_A_Friend_Mobile.png");
    background-size: cover;
    @media screen and (min-width: $sm) {
      background-image: url("../assets/images/hero/Become_A_Friend.png");
    }
  }
}

.decoration {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 160%;
  z-index: 0;
}


.desktop-lines {
  position: absolute;
  width: calc(100% + 30px);
  top: 0;
  left: -15px;
  height: 100%;
  @include MQ(S) {
    display: none;
  }

  span {
    display: block;
    width: 450px;
    height: 8000px;
    background-color: $blue;
    position: absolute;
    content: "";
    transform: translate(-50%, -50%) rotate(-45deg);
    top: 0;
    margin-left: 2345px;
    animation: fadeLine 1s ease forwards;

    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 100%;
      right: -25px;
      opacity: 0;
      background: white;
      animation: fadeIn .33s ease 1s forwards;
    }
  }
}

.background {
  > .container {
    > .content {
      > .section-main-content {
        flex-basis: 550px;
        width: 550px;
        max-width: 100%;
        padding-left: 50px;
        margin: 0;
      }

      > .form {
        flex-basis: 55%;
        margin-right: 0;
        position: relative;
        opacity: 0;
        animation: fadeInRight 1s ease forwards;

        &:before {
          content: "";
          position: absolute;
          right: -15px;
          width: 15px;
          background: white;
          height: 100%;

        }

        &:after {
          position: absolute;
        }

        > .container {
          padding: 25px 50px;
        }
      }
    }
  }
}

@keyframes fadeLine {
  0% {
    transform: rotate(-45deg) translateX(221%) translateY(-100%)
  }
  100% {
    transform: rotate(-45deg) translateX(221%) translateY(-32%)
  }
}


</style>
